import axios from 'axios';
import React, { useEffect, useState } from 'react'

const CategoryEditModal = ({ closeModal, id }) => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://api.turbodelivery.mn/categories?_id=' + id)
                .then((e) => {
                    // window.location.reload(false);
                    setCategory(e.data.data[0]);
                    setName(e.data.data[0].name);
                    setLoading(false)
                })
                .catch((e) => e)
        }

    }, [isLoading])

    const editCategory = () => {
        axios.put('https://api.turbodelivery.mn/categories/' + id, {
            name: name,
            user: category.user,
        })
            .then(() => window.location.reload(false))
            .catch((e) => e)
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Барааны бүлэг нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Бүлэг нэр:
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} />
                </label>
                <div className='button_container'>
                    <button onClick={() => editCategory()} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default CategoryEditModal