import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import ContainerStatusChangeModal from '../Modal/EditModal/ContainerStatusChangeModal';
import { CiEdit } from "react-icons/ci";
import TablePagination from '@mui/material/TablePagination';
import { LiaCommentsSolid } from "react-icons/lia";
import CommentModal from '../Modal/Uttils/CommentModal';
import axios from 'axios';
import ContainerItemsDetialModal from '../Modal/DetialModal/ContainerDetialModal';
// import CommentModal from '../Modal/Uttils/CommentModal';
import { IoIosAddCircleOutline } from "react-icons/io";

export default function ContainerOfPackageTable({ containers, user, users, filterStatus, shops }) {
    const [isModal, setModal] = React.useState(false);
    const [id, setId] = React.useState('');
    const [reportId, setReportId] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
    const [comment, setComment] = React.useState('');
    const [commentModal, setCommentModal] = React.useState(false);
    const [detialModal, setDetialModal] = React.useState(false);
    const [itemUser, setItemUser] = React.useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
    };

    const closeModal = () => {
        setModal(false);
        setCommentModal(false);
        setDetialModal(false);
    }

    return (
        <>
            {detialModal && itemUser ? <ContainerItemsDetialModal containers={containers.filter((e) => itemUser === e._id)} user={user} users={users}
                filterStatus={filterStatus} shops={shops} closeModal={closeModal}
            /> : null}
            {commentModal ? <CommentModal closeModal={closeModal} text={comment} /> : <></>}
            {isModal && id !== '' && reportId !== '' ?
                <ContainerStatusChangeModal id={id} reportId={reportId} closeModal={closeModal} /> : <></>}
            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                            <TableCell className='table-item' style={{ color: '#fff' }}><p className='table-title'></p> </TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }}><p className='table-title'>Id</p> </TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }}> <p className='table-title'>Агуулах</p></TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }}> <p className='table-title'>Харилцагч</p></TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Дэлгүүр</p></TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Төлөв</p></TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Барааны тоо ширхэг</p></TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Бүртгэсэн огноо</p></TableCell>
                            <TableCell className='table-item' style={{ color: '#fff' }} align="center"><p className='table-title'>Үйлдэл</p></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {containers
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .filter((element) => filterStatus ?
                                element.status === filterStatus : element
                            ).filter((el) => user ? el.user === user : el).map((e, index) =>
                            (
                                <TableRow className='hover'
                                    key={e._id}
                                >
                                    <TableCell align='center'>
                                        <IoIosAddCircleOutline size={30} cursor={'pointer'}
                                            color='#838383' onClick={() => {
                                                setItemUser(e._id)
                                                setDetialModal(true);
                                            }} />
                                    </TableCell>
                                    <TableCell align="left">{1 + index}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {/* {row.container} */}
                                        {/* <br /> */}
                                        Turbo delivery <br />
                                        агуулах
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {users.filter((el) => el._id === e.user).map((el) => (
                                            <p>{el.name}</p>
                                        ))}
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            e.canceled === false && e.shop ? e.shop :
                                                shops.filter((el) => el.user === e.user).map((e) => (
                                                    <p>{e.name}</p>
                                                ))
                                        }</TableCell>
                                    <TableCell align="center">
                                        <div className='line-start'>
                                            {e.comment ?
                                                < LiaCommentsSolid
                                                    onClick={() => {
                                                        setComment(e.comment);
                                                        setCommentModal(true);
                                                    }}
                                                    cursor={'pointer'} size={20} color='red' /> : <></>}
                                            <p className={e.item.find((e) => e.status !== '')?.status
                                                === 'Хүлээж авсан' ? 'green-border' :
                                                e.item.find((e) => e.status !== '')?.status === 'Зарлага' ? 'red-border' : 'blue-border'}>
                                                {e.item.find((e) => e.status !== '')?.status}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">{e.item.length}</TableCell>
                                    <TableCell align="center">{dayjs(e.createdAt).format('YYYY-MM-DD')}</TableCell>
                                    <TableCell align="center">
                                        <div>
                                            <CiEdit
                                                onClick={() => {
                                                    setModal(true);
                                                    setReportId(e.reportId);
                                                    setId(e.id)
                                                }}
                                                size={30} style={{ cursor: 'pointer' }} color='blue'
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                            )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={containers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
}