import React, { useEffect, useState } from 'react'
import styles from './Driver.module.css'
import axios from 'axios';

const Driver = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState('');

  const containerId = window.localStorage.getItem('containerId');

  useEffect(() => {
    if (isLoading) {
      axios.get('https://api.turbodelivery.mn/order?sort=-createdAt')
        .then((e) => { setOrders(e.data.data.filter((e) => e.container === containerId)); setIsLoading(false) })
        .catch((e) => console.log(e));
    }
  }, [isLoading]);

  const changeStatus = ({ id }) => {
    // alert(status);
    axios.put('https://api.turbodelivery.mn/order/proto/driver/' + id, {
      status: status
    })
      .then(() => alert('s'))
      .catch((e) => alert(e));
  }

  return (
    <div className={styles.container}>
      {orders.map((e) => (
        <div className='modal'>
          <h4>{e.phone} - {e.address}</h4>
          <select onChange={(e) => setStatus(e.target.value)}>
            {/* 'Хүлээгдэж байна', 'Жолооч авсан', 'Хаягаар очсон', 'Хүргэсэн', 'Буцаагдсан' */}
            <option value="Хүлээгдэж байна">Хүлээгдэж байна</option>
            <option value="Буцаагдсан">Буцаагдсан</option>
            <option value="Жолооч авсан">Жолооч авсан</option>
            <option value="Хаягаар очсон">Хаягаар очсон</option>
            <option value="Хүргэсэн">Хүргэсэн</option>
          </select>
          <button onClick={() => changeStatus({ id: e._id })} className='button-white'>save</button>
        </div>
      ))}
    </div>
  )
}

export default Driver
