import React, { useEffect, useState } from 'react';
import Navbar from '../../../Components/Navbar/Navbar';
import axios from 'axios';
import styles from './Driver_Orders.module.css'
import DriverOrderTable from '../../../Utills/Table/DriverOrderTable';
import DriverListTable from '../../../Utills/Table/DriverListTable';

const Driver_Orders = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  // const [drivers, setDrivers] = useState([]);

  const container = window.localStorage.getItem('containerId');

  useEffect(() => {
    window.localStorage.setItem('side-id', 9);
    if (isLoading) {

      axios.get('https://api.turbodelivery.mn/order?sort=-createdAt')
        .then((e) => { setOrders(e.data.data.filter((e) => e.container === container)); setIsLoading(false) })
        .catch((e) => alert(e));

      axios.get('https://api.turbodelivery.mn/user')
        .then((e) => {
          setUsers(e.data.data);
          setIsLoading(false);
        })

      // axios.get('https://api.turbodelivery.mn/user?role=driver')
      //   .then((e) => {
      //     setDrivers(e.data.data);
      //     setIsLoading(false);
      //   })
    };
  })

  const refresh = () => {
    setIsLoading(true);
  }

  const turnOfRefresh = () => {
    setIsLoading(false);
  }

  return (
    <>
      <Navbar title={'Тооцоо'} />
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <DriverListTable turnof={turnOfRefresh} refresh={refresh} users={users} orders={orders} />
        <div className='divider'></div>
        <DriverOrderTable refresh={refresh} turnof={turnOfRefresh} orders={orders} users={users} />
      </div>
    </>
  );
}

export default Driver_Orders
