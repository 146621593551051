import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Pages/Dashboard/Dashboard';
import Order from './Pages/Order/Order';
import Finance from './Pages/Finance/Finance';
import Container from './Pages/Container/Container';
import Items from './Pages/Directry/Items/Items';
import Category from './Pages/Directry/Category/Category';
import Shops from './Pages/Shops/Shops';
import Accounting from './Role/Accounting/Accounting';
import Driver from './Role/Driver/Driver';
import ContainerReport from './Pages/Report/ContainerReport/ContainerReport';
import ShopReport from './Pages/Report/ShopReport/ShopReport';
import Users from './Pages/Users/Users';
import Driver_Orders from './Pages/Driver/Driver_Orders/Driver_Orders';
import DriverHistory from './Pages/Driver/Driver_History/Driver_History';
import OrderFiltered from './Pages/Order/OrderFiltered';
import OrderItems from './Pages/Order/OrderItems';
import ContainerOfPackage from './Pages/Container/ContainerOfPackage';

function App() {
  const user = window.localStorage.getItem('userId');
  return (
    <>
      <Router>
        {user !== null ? <Sidebar /> : ''}
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/dashboard' element={
            <>
              <Dashboard />
            </>
          } />
          <Route path='/order' element={<Order />} />
          <Route path='/orderdetial/:id' element={<OrderFiltered />} />
          <Route path='/finance' element={<Finance />} />
          <Route path='/container' element={<Container />} />
          <Route path='/containerpackage' element={<ContainerOfPackage />} />
          <Route path='/items' element={<Items />} />
          <Route path='/category' element={<Category />} />
          <Route path='/shop' element={<Shops />} />
          <Route path='/accounting' element={<Accounting />} />
          <Route path='/driver' element={<Driver />} />
          <Route path='/reportcontainer' element={<ContainerReport />} />
          <Route path='/reportshop' element={<ShopReport />} />
          <Route path='/driverorder' element={<Driver_Orders />} />
          <Route path='/driverhistory' element={<DriverHistory />} />
          <Route path='/users' element={<Users />} />
          <Route path='/orderitems' element={<OrderItems />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
