import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import dayjs from 'dayjs';
import { TableSortLabel } from '@mui/material';

export default function ReportContainerTable({ report, endDate, startDate, user, users, shops, orders, container }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const [sortBy, setSortBy] = React.useState(null); // State to store the column being sorted
  const [sortOrder, setSortOrder] = React.useState('asc'); // State to store the sorting order

  const calculateTotalDownload = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name.includes(name))
            .filter((el) => el.status === 'Хүлээж авсан')
            // .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal +
                (
                  (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(endDate))
                    || (dayjs(item.date).isSame(endDate, 'date') && dayjs(item.date).isSame(startDate, 'date'))
                    ? item.staticUnit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalDownloadBalance = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name.includes(name))
            .filter((el) => el.status === 'Хүлээж авсан')
            // .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + item.staticUnit,
              // (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(dayjs(endDate)) ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalOrdersBalance = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name.includes(name))
          .reduce((itemTotal, item) => itemTotal + item.unit, 0);
      }, 0);
  };

  const calculateTotalDelivered = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .filter((e) => e.status === 'Хүргэсэн' || e.status === 'Хэсэгчлэн хүргэсэн')
      .filter((e) => {
        return (dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate) &&
          dayjs(e.orderDate).subtract(8, 'hour').isAfter(endDate))
          || (dayjs(e.orderDate).subtract(8, 'hour').isSame(endDate, 'date') &&
            dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
      })
      .reduce((total, items) => {
        return total +
          items.order
            .filter((e) => {
              return e.name.includes(name)
            })
            .reduce((itemTotal, item) => itemTotal +
              item.unit, 0);
      }, 0);
  };

  const calculateTotalCancleled = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name.includes(name))
          .reduce((itemTotal, item) => itemTotal +
            (
              ((dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
                dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
              ) ?
                item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalRemoved = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.item
          .filter((e) => {
            return e.name.includes(name)
          })
          .filter((el) => el.status === 'Зарлага')
          // .filter((el) => el.switchsuccess === true)
          .reduce((itemTotal, item) => itemTotal +
            (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
              || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'dates')
              ? item.staticUnit : 0), 0);
      }, 0);
  };

  const calculateTotalOrders = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name.includes(name))
          .reduce((itemTotal, item) => itemTotal +
            (
              (
                (dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate)
                  && dayjs(report.orderDate).subtract(8, 'hour').isAfter(endDate))
                || (dayjs(report.orderDate).subtract(8, 'hour').isSame(endDate, 'date')
                  && dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
              )
                ? item.unit : 0), 0);
      }, 0);
  };

  // Filter


  const calculateTotalOrdersBalanceFilter = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name.includes(name))
          .reduce((itemTotal, item) => itemTotal + (
            (dayjs(report.orderDate).subtract(8, 'hour').isBefore(startDate)
              || dayjs(report.orderDate).subtract(8, 'hour').isSame(startDate, 'hour')) ? item.unit : 0
          ), 0);
      }, 0);
  };

  const calculateTotalDownloadBalanceFilter = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      .filter((e) => e.canceled === false)
      .reduce((total, report) => {
        return (
          total +
          report.item
            .filter((e) => e.name.includes(name))
            .filter((el) => el.status === 'Хүлээж авсан')
            // .filter((el) => el.switchsuccess === true)
            .reduce(
              (itemTotal, item) =>
                itemTotal + ((dayjs(item.date).isBefore(startDate) || dayjs(item.date).isSame(startDate, 'hour')) ? item.staticUnit : 0),
              // (dayjs(item.date).isBefore(startDate) && dayjs(item.date).isAfter(dayjs(endDate)) ? item.unit : 0),
              0
            )
        );
      }, 0);
  };

  const calculateTotalCancleledFilter = ({ name, userContainer }) => {
    return orders
      .filter((e) => userContainer === e.user)
      .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
      .filter((e) => dayjs(e.orderDate).subtract(8, 'hour').isBefore(startDate) ||
        dayjs(e.orderDate).subtract(8, 'hour').isSame(startDate, 'date'))
      .reduce((total, report) => {
        return total + report.order
          .filter((e) => e.name.includes(name))
          .reduce((itemTotal, item) => itemTotal +
            (
              ((dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
                dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
              ) ?
                item.unit : 0), 0);
      }, 0);
  };

  const calculateTotalRemovedFilter = ({ name, userContainer }) => {
    return container
      .filter((e) => userContainer === e.user)
      .reduce((total, report) => {
        return total + report.item
          .filter((e) => {
            return e.name.includes(name)
          })
          .filter((el) => el.status === 'Зарлага')
          // .filter((el) => el.switchsuccess === true)
          // .filter((el) => dayjs(el.date))
          .filter((e) => dayjs(e.date).subtract(8, 'hour').isBefore(startDate) ||
            dayjs(e.date).subtract(8, 'hour').isSame(startDate, 'date'))
          .reduce((itemTotal, item) => itemTotal +
            (dayjs(item.date).isBefore(dayjs().add(8, 'hour'))
              || dayjs((item.date)).isSame(dayjs().add(8, 'hour'), 'date')
              ? item.staticUnit : 0), 0);
      }, 0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnName);
      setSortOrder('asc');
    }
  };

  const sortedReport = React.useMemo(() => {
    if (!sortBy) return report;

    // Sort the report array based on the sorting column and order
    return [...report].sort((a, b) => {
      let comparison = 0;
      if (a[sortBy] > b[sortBy]) {
        comparison = 1;
      } else if (a[sortBy] < b[sortBy]) {
        comparison = -1;
      }
      return sortOrder === 'asc' ? comparison : -comparison;
    });
  }, [report, sortBy, sortOrder]);

  return (
    <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
      <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
        <TableHead>
          <TableRow style={{ background: '#2c9961', color: '#fff' }}>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>Харилцагч</TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>
              <TableSortLabel
                active={sortBy === 'name'} // Set active state based on whether 'name' is the sorting column
                direction={sortBy === 'name' ? sortOrder : 'asc'}
                onClick={() => handleSort('name')} // Call handleSort with column name on click
              >
                Барааны нэр
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>
              <TableSortLabel
                active={sortBy === 'name'} // Set active state based on whether 'name' is the sorting column
                direction={sortBy === 'name' ? sortOrder : 'asc'}
                onClick={() => handleSort('name')} // Call handleSort with column name on click
              >
                Татан авалт
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>
              <TableSortLabel
                active={sortBy === 'name'} // Set active state based on whether 'name' is the sorting column
                direction={sortBy === 'name' ? sortOrder : 'asc'}
                onClick={() => handleSort('name')} // Call handleSort with column name on click
              >
                Хүргэгдсэн
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>
              <TableSortLabel
                active={sortBy === 'name'} // Set active state based on whether 'name' is the sorting column
                direction={sortBy === 'name' ? sortOrder : 'asc'}
                onClick={() => handleSort('name')} // Call handleSort with column name on click
              >
                Шүүлттэй хүргэлт
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>
              <TableSortLabel
                active={sortBy === 'name'} // Set active state based on whether 'name' is the sorting column
                direction={sortBy === 'name' ? sortOrder : 'asc'}
                onClick={() => handleSort('name')} // Call handleSort with column name on click
              >
                Одоогын үлдэгдэл
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ color: '#fff', fontSize: 16 }}>
              <TableSortLabel
                active={sortBy === 'name'} // Set active state based on whether 'name' is the sorting column
                direction={sortBy === 'name' ? sortOrder : 'asc'}
                onClick={() => handleSort('name')} // Call handleSort with column name on click
              >
                Нийт захиалга
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedReport
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e, index) => (
              <TableRow className='hover' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  {shops.filter((el) => el.user.includes(e.user)).map((e) => (
                    <p key={e.id}>{e.name}</p>
                  ))}
                </TableCell>
                <TableCell component="th" scope="row">{e.name}</TableCell>
                {/* Render other table cells */}
                <TableCell component="th" scope="row">{e.name}</TableCell>
                <TableCell align="center">{calculateTotalDownload({ name: e.name, userContainer: e.user })}</TableCell>
                <TableCell align="center">{calculateTotalDelivered({ name: e.name, userContainer: e.user })}</TableCell>
                <TableCell align="center">{
                  calculateTotalDownloadBalanceFilter({ name: e.name, userContainer: e.user }) -
                  calculateTotalOrdersBalanceFilter({ name: e.name, userContainer: e.user }) +
                  calculateTotalCancleledFilter({ name: e.name, userContainer: e.user }) -
                  calculateTotalRemovedFilter({ name: e.name, userContainer: e.user })
                }
                </TableCell>
                <TableCell align="center">{
                  calculateTotalDownloadBalance({ name: e.name, userContainer: e.user }) -
                  calculateTotalOrdersBalance({ name: e.name, userContainer: e.user }) +
                  calculateTotalCancleled({ name: e.name, userContainer: e.user }) -
                  calculateTotalRemoved({ name: e.name, userContainer: e.user })
                }
                </TableCell>
                <TableCell align="center">{calculateTotalOrders({ name: e.name, userContainer: e.user })}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={report.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>)
}