import axios from 'axios';
import React, { useState } from 'react'

const FinanceSuccess = ({ closeModal, orderDate }) => {
    const [success, setSuccess] = useState(false);

    const updateFinance = ({ orderDate }) => {
        axios.put('https://api.turbodelivery.mn/finance/' + orderDate, {
            success: success,
        })
            .then(() => window.location.reload(false))
            .catch((e) => alert(e))

        // .then(() => {})
    }

    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Тооцоо нийлсэн эсэх</h2>
                    <h2 style={{ cursor: 'pointer' }}>X</h2>
                </div>
                <select onChange={(e) => setSuccess(e.target.value)} name="" id="">
                    <option value={true}>Тооцоо нийлсэн</option>
                    <option value={false}>Тооцоо нийлээгүй</option>
                </select>
                <div className='button_container'>
                    <button onClick={updateFinance} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default FinanceSuccess
