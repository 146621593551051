import { Tooltip } from '@mui/material';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { RiFileExcel2Line } from "react-icons/ri";

const ExportExcel = ({ excelData, fileName, haveOrders, driver }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const exportToOrderExcel = () => {
        const flatData = excelData.flatMap(order => {
            return order.order.map(item => ({
                утас: order.phone,
                хаяг: order.address,
                төлөв: order.status,
                'нийт дүн': new Intl.NumberFormat('en-Us').format(order.price),
                огноо: order.createdAt,
                жолооч: driver,
                'бараа нэр': item.name,
                'бараа дүн': new Intl.NumberFormat('en-Us').format(item.price),
                'бараа тоо': item.unit
            }));
        });

        const columnLengths = flatData.reduce((acc, row) => {
            Object.entries(row).forEach(([key, value]) => {
                acc[key] = Math.max(acc[key] || 0, String(value).length);
            });
            return acc;
        }, {});

        const ws = XLSX.utils.json_to_sheet(flatData);
        Object.keys(columnLengths).forEach((key) => {
            ws[`!cols`] = ws[`!cols`] || [];
            ws[`!cols`].push({ wch: columnLengths[key] + 5 });
        });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Tooltip>
                {haveOrders ? <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}
                    className='button-white' onClick={(e) => exportToOrderExcel(fileName)}>
                    <RiFileExcel2Line color='green' />
                    Excel File
                </button> :
                    <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}
                        className='button-white' onClick={(e) => exportToExcel(fileName)}>
                        <RiFileExcel2Line color='green' />
                        Excel File
                    </button>}
            </Tooltip>
        </>
    )
}

export default ExportExcel;