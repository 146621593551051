import React, { useEffect, useState } from 'react'
// import ContainerAddTable from '../Table/AddTable/ContainerAddTable'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IoTrashBin } from "react-icons/io5";
import axios from 'axios';
import dayjs from 'dayjs';

const ContainerSwitchModal = ({ closeModal }) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [container, setContainer] = useState('');
    const [location, setLocation] = useState('');
    const [shop, setShop] = useState({});
    const [user, setUser] = useState('');
    const [users, setUsers] = useState([]);
    const [shops, setShops] = useState([]);
    const [inputs, setInputs] = useState([{ name: "", unit: "", reportId: '', staticUnit: '', comment: '', switchsuccess: false }]);
    const [blank, setblank] = useState([]);
    const [orders, setOrders] = useState([]);
    const [containers, setContainers] = useState([]);
    const [areacontainers, setAreaContainers] = useState([]);
    // const [areacontainer, setAreaContainer] = useState('');
    // const id = useId();

    const userCache = JSON.parse(window.localStorage.getItem('userId'));
    const containerId = window.localStorage.getItem('containerId');

    useEffect(() => {
        if (!isLoading) {
            console.log(inputs[inputs.length - 1].unit);
            const lastItemBalance = inputs.filter((e) => parseInt(e.staticUnit) === 0 || e.staticUnit === '' ||
                e.comment === '' || e.name === ''
            );

            // console.log('last' + JSON.parse(lastItemBalance));
            lastItemBalance.forEach((e) => {
                console.log(e);
            })

            setblank(lastItemBalance);
        }

        if (isLoading) {

            axios.get('https://api.turbodelivery.mn/container')
                .then((e) => {
                    setContainers(e.data.data.filter((e) => e.areacontainer === containerId));
                })
                .catch((e) => console.log(e))

            axios.get('https://api.turbodelivery.mn/order')
                .then((e) => {
                    setOrders(e.data.data.filter((e) => e.container === containerId));
                })
                .catch((e) => console.log(e))

            axios.get('https://api.turbodelivery.mn/post')
                .then((e) => {
                    // setIsLoading(false);
                    setItems(e.data);
                })
                .catch((e) => console.log(e));

            axios.get('https://api.turbodelivery.mn/areacontainer')
                .then((e) => {
                    // setIsLoading(false);
                    setAreaContainers(e.data.data);
                })
                .catch((e) => console.log(e));

            axios.get('https://api.turbodelivery.mn/user?role=user')
                .then((e) => {
                    setIsLoading(false);
                    setUsers(e.data.data);
                })
                .catch((e) => console.log(e));
            axios.get('https://api.turbodelivery.mn/shop')
                .then((e) => {
                    setIsLoading(false);
                    setShops(e.data.data);
                })
                .catch((e) => console.log(e));
        }
    }, [isLoading, inputs]);

    const calculateTotalDownloadBalance = ({ name, userId }) => {
        return containers
            .filter((e) => e.canceled === false)
            .filter((e) => e.user === userId)
            .reduce((total, report) => {
                return (
                    total +
                    report.item
                        .filter((e) => e.name === name)
                        .filter((el) => el.status === 'Хүлээж авсан')
                        .reduce(
                            (itemTotal, item) =>
                                itemTotal + item.staticUnit,
                            0
                        )
                );
            }, 0);
    };

    const calculateTotalOrdersBalance = ({ name, userId }) => {
        return orders
            .filter((e) => e.user === userId)
            .reduce((total, report) => {
                return total + report.order
                    .filter((e) => e.name === name)
                    .reduce((itemTotal, item) => itemTotal + item.unit, 0);
            }, 0);
    };

    const calculateTotalCancleled = ({ name, userId }) => {
        return orders
            .filter((e) => e.user === userId)
            .filter((e) => e.status === 'Буцаагдсан' || e.status === 'Хаягаар очсон')
            .reduce((total, report) => {
                return total + report.order
                    .filter((e) => e.name === name)
                    .reduce((itemTotal, item) => itemTotal +
                        (
                            ((dayjs(report.orderDate).add(16, 'hours').isBefore(dayjs().add(8, 'hour')) ||
                                dayjs(report.orderDate).add(16, 'hours').isSame(dayjs().add(8, 'hour'), 'date'))
                            ) ?
                                item.unit : 0), 0);
            }, 0);
    };

    const calculateTotalRemoved = ({ name, userId }) => {
        return containers
            .filter((e) => e.user === userId)
            .reduce((total, report) => {
                return total + report.item
                    .filter((e) => e.name === name)
                    .filter((el) => el.status === 'Зарлага')
                    .reduce((itemTotal, item) => itemTotal +
                        (dayjs((item.date)).isSame(dayjs(), 'dates')
                            ? item.staticUnit : 0), 0);
            }, 0);
    };

    const createContainer = () => {
        setIsLoading(true);
        axios.post('https://api.turbodelivery.mn/container', {
            item: inputs,
            container: container,
            areacontainer: container,
            location: location,
            shop: shop,
            user: user,
            switchitem: true
        })
            .then(() => {
                axios.post('https://api.turbodelivery.mn/container/minus', {
                    item: inputs.map(input => ({
                        ...input,
                        status: "Зарлага"
                    })),
                    canceled: true,
                    container: containerId,
                    areacontainer: containerId,
                    location: location,
                    shop: shop,
                    user: user,
                    switchitem: true
                    // user: user._id,
                })
                    .then(() => {
                        window.location.reload(false);
                        closeModal(false);
                    })
                    .catch((e) => console.log(e))
            })
            .catch((e) => console.log(e))
    }

    const handleChange = (event, index) => {
        let { name, value } = event.target;
        let onChangeValue = [...inputs];
        onChangeValue[index][name] = value;

        // Update staticUnit to the same value as unit
        // onChangeValue[index]['staticUnit'] = value['unit'];

        setInputs(onChangeValue);
    };


    const handleAddInput = () => {
        setInputs([...inputs, { name: "", unit: "", reportId: "", staticUnit: '', comment: '' }]);
    }

    const handleDeleteInput = (index) => {
        const newArray = [...inputs];
        newArray.splice(index, 1);
        setInputs(newArray);
    };

    return (
        <>{isLoading ? <div className="loader-container"><div className="loader"></div></div> :
            <div className='blur_container'>
                <div className='modal' style={{ width: '70vw' }}>
                    <div className='two-line' style={{ width: '70vw' }}>
                        <h2>Агуулахад бараа шүлжүүлэх</h2>
                        <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                    </div>
                    <br />
                    <label>
                        Илгээх агуулах сонгох:
                        <select onChange={(e) => setContainer(e.target.value)} name="" id="">
                            <option value="">Сонгох</option>
                            {areacontainers.map((e) => (
                                <option value={e._id}>{e.name}</option>
                            ))}
                        </select>
                    </label>
                    <label>
                        Харилцагч сонгох:
                        <select onChange={(e) => {
                            setUser(e.target.value);
                        }} name="" id="">
                            <option value="">Сонгох</option>
                            {/* <option value="2">2</option> */}
                            {isLoading == false ? users.map((e) => (
                                <option value={e._id}>{e.name}</option>
                            )) : <></>}
                        </select>
                    </label>
                    <label>
                        Дэлгүүр сонгох:
                        <select onChange={(e) => {
                            setShop(e.target.value);
                        }} name="" id="">
                            <option value="">Сонгох</option>
                            {/* <option value="2">2</option> */}
                            {isLoading == false ? shops
                                .filter((e) => e.user === user)
                                .map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                )) : <></>}
                        </select>
                    </label>
                    <button onClick={() => handleAddInput()} className='button-white'>Мөр Нэмэх</button>
                    {/* <ContainerAddTable unit_arrey={inputs}/> */}
                    <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                        <Table sx={{ minWidth: '40vw', maxWidth: '70vw' }} aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                                    <TableCell style={{ color: '#fff', fontSize: 16 }} align='start' >Бараа</TableCell>
                                    <TableCell style={{ color: '#fff', fontSize: 16 }} align='center' >Үлдэгдэл</TableCell>
                                    <TableCell style={{ color: '#fff', fontSize: 16 }} align='center' >Тоо хэмжээ</TableCell>
                                    <TableCell style={{ color: '#fff', fontSize: 16 }} align='center' >Тайлбар</TableCell>
                                    <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inputs
                                    .map((row, index) => (
                                        <TableRow
                                            className='hover'
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            key={index}
                                        >
                                            <TableCell component="th" scope="row">
                                                <select name="name" id="" onChange={(e) => handleChange(e, index)}>
                                                    <option value="">Сонгох</option>
                                                    {items
                                                        .filter((e) => e.user === user)
                                                        .filter((e) => user ? e.user === user : e)
                                                        .map((e) => (
                                                            <option key={e.id} value={e.title}>{e.title}</option>
                                                        ))}
                                                </select>
                                            </TableCell>
                                            <TableCell align="center">
                                                {items
                                                    .filter((e) => e.user === user)
                                                    .map((e) => (
                                                        e.title && inputs[index]?.name === e.title ? (
                                                            <option name='unit' key={e.id}>
                                                                {
                                                                    calculateTotalDownloadBalance({ name: e.title, userId: e.user }) -
                                                                    calculateTotalOrdersBalance({ name: e.title, userId: e.user }) +
                                                                    calculateTotalCancleled({ name: e.title, userId: e.user }) -
                                                                    calculateTotalRemoved({ name: e.title, userId: e.user })
                                                                }
                                                            </option>
                                                        ) : null
                                                    ))}
                                            </TableCell>
                                            <TableCell align="center">
                                                <input type="number" name='staticUnit' onChange={(e) => handleChange(e, index)} id="" />
                                            </TableCell>
                                            <TableCell align="center">
                                                <input type="text" name='comment' onChange={(e) => handleChange(e, index)} id="" />
                                            </TableCell>
                                            <TableCell align="center">
                                                {/* uildel */}
                                                {/* <input style={{visibility: 'hidden'}} type="number" name='staticUnit' onChange={(e) => handleChange(e, index)} id="" /> */}
                                                <IoTrashBin size={30} color='red' onClick={handleDeleteInput} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <div className="body"> {JSON.stringify(inputs)} </div> */}
                    <div className='button_container'>
                        {/* <button onClick={createContainer} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button> */}

                        {blank.length === 0 ?
                            // <button onClick={createContainer} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button> 
                            <button onClick={createContainer} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                            :
                            <button className='button-white' style={{ color: '#fff', background: '#d3d3d3' }}>Хадгалах</button>}
                        <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                    </div>
                </div>
            </div>}</>
    )
}

export default ContainerSwitchModal;
