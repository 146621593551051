import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import { CiEdit } from "react-icons/ci";
import CategoryEditModal from '../Modal/EditModal/CategoryEditModal';

export default function CategoryTable({ categories, users, user }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
  const [id, setId] = React.useState('');
  const [isModal, setModal] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
  };

  const closeModal = () => {
    setModal(false);
  }
  return (
    <>
      {isModal && id ? <CategoryEditModal id={id} closeModal={closeModal} /> : null}
      <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
        <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: '#2c9961', color: '#fff' }}>
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Харилцагч</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }}>Барааны бүлэг</TableCell>
              <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.filter((e) => user ? e.user === user : e)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow className='hover'
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{users
                    .filter((e) => e._id.includes(row.user))
                    .map((e) => (
                      <p>{e.name}</p>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    <CiEdit
                      onClick={() => { setModal(true); setId(row._id) }}
                      size={30} style={{ cursor: 'pointer' }} color='blue'
                    />
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={categories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}