import React, { useEffect, useState } from 'react'
import styles from './Items.module.css';
import ItemsTable from '../../../Utills/Table/ItemsTable';
import Navbar from '../../../Components/Navbar/Navbar';
import ItemsAddModal from '../../../Utills/Modal/ItemsAddModal';
import axios from 'axios';
import add_icon from '../../../Assets/uttils/add_button.svg';

const Items = () => {
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [user, setUser] = useState('');

  const closeModal = () => {
    setIsModal(false);
  }

  useEffect(() => {
    if (isLoading) {
      window.localStorage.setItem('side-id', 5);
      axios.get('https://api.turbodelivery.mn/post?sort=-createdAt')
        .then((e) => {
          setPosts(e.data);
          setIsLoading(false)
        })
        .catch((e) => console.log(e));

      axios.get('https://api.turbodelivery.mn/user?role=user')
        .then((e) => {
          setUsers(e.data.data);
          setIsLoading(false)
        })
        .catch((e) => console.log(e));

      axios.get('https://api.turbodelivery.mn/shop')
        .then((e) => {
          setShops(e.data.data);
          setIsLoading(false)
        })
        .catch((e) => console.log(e));
    }
  }, [isLoading])

  return (
    <>
      {!isModal ? <Navbar title={'Бараа'} /> : <></>}
      {isModal ? <div className='abs'><ItemsAddModal closeModal={closeModal} /></div> : <></>}
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <div className={styles.container}>
        <div className='space-between' style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
          <label>
            Барааны нэрээр хайх:
            <input value={search} onChange={(e) => setSearch(e.target.value)} className='search' placeholder='Search' type="text" />
          </label>
          <label>
            Дэлгүүрээр хайх:
            <select onChange={(e) => setUser(e.target.value)} name="" id="">
              <option value="">Сонгох</option>
              {shops.map((e) => (
                <option value={e.user}>{e.name}</option>
              ))}
            </select>
          </label>
          <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
        </div>
        <ItemsTable search={search} users={users} user={user} posts={posts} />
      </div>
    </>
  )
}

export default Items
