import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import dayjs from 'dayjs';
import axios from 'axios';
import { SendOutlined } from '@mui/icons-material';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Утас / Хаяг',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    label: 'Харилцагч',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Төлөв',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    label: 'Нийт дүн',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Огноо',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Жолооч',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Үйлдэл',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: '#e2e2e2' }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, click, future, tommorowClick } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} сонгогдсон
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Захиалга сонгох
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip style={{ width: '20vw' }} title='Маргаашын захиалгад нэмэх'>
          <button className='button' onClick={tommorowClick} htmlFor="">
            Маргаашын захиалга
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <FilterListIcon /> */}
          </IconButton>
        </Tooltip>
      )}



      {numSelected > 0 ? (
        <Tooltip title="Жолооч-руу захиалга илгээх ">
          <button className='button' onClick={click} htmlFor="">
            Энгийн захиалга
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function DriverOrderTable({ orders, users, refresh }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [driver, setDriver] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [tommorowSelect, setTommorowSelect] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isFuture, setIsFuture] = React.useState(false);

  const futureOrder = () => {
    setIsFuture(!isFuture);
  }

  const updateDriverToOrder = () => {
    refresh();
    axios.put('https://api.turbodelivery.mn/user/driver/' + driver, {
      driverOrder: selected.filter((e) => e.orderId !== ''),
    })
      .then(() => window.location.reload(false))
      .catch((e) => alert(e))
  };

  const updateTommorowDriverToOrder = () => {
    refresh();
    axios.put('https://api.turbodelivery.mn/user/driver/' + driver, {
      driverOrder: tommorowSelect.filter((e) => e.orderId !== ''),
    })
      .then(() => window.location.reload(false))
      .catch((e) => alert(e))
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = orders
        .filter((e) => e.orderId !== '' && (e.status === 'Хүлээгдэж байна' || e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан'))
        .map((order) => ({
          orderId: order._id,
          orderItems: order.order,
          isTommorow: false,
          orderDate: dayjs().add(8, 'hour'),
        }));

      const newTommorowSelected = orders
        .filter((e) => e.orderId !== '' && (e.status === 'Хүлээгдэж байна' || e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан'))
        .map((order) => ({
          orderId: order._id,
          orderItems: order.order,
          isTommorow: true,
          orderDate: dayjs().add(1, 'day').add(8, 'hour'),
        }));

      setSelected(newSelected);
      setTommorowSelect(newTommorowSelected);
      // alert(JSON.stringify(newSelected));
      return;
    }
    setSelected([]);
  };


  const handleClick = (event, id, orderItems) => {
    // const selectedIndex = selected.indexOf({orderId: id});
    const selectedIndex = selected.findIndex((item) => item.orderId === id);
    let newSelected = [...selected];

    if (selectedIndex === -1) {
      newSelected.push({
        orderId: id,
        orderItems: orderItems,
        isTommorow: false,
        orderDate: dayjs().add(8, 'hour'),
      });
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    let newTommorowSelected = [...tommorowSelect];

    if (selectedIndex === -1) {
      newTommorowSelected.push({
        orderId: id, orderItems: orderItems,
        isTommorow: true,
        orderDate: dayjs(order.orderDate).add(1, 'day').add(8, 'hour'),
      });
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelected(newSelected);
    setTommorowSelect(newTommorowSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.findIndex((e) => e.orderId === id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '88vw', mb: 2 }}>
        <br />
        <label htmlFor="">
          {/* <br /> */}
          Жолооч сонгох:
          <select value={driver} onChange={(e) => setDriver(e.target.value)} name="" id="">
            <option value=""></option>
            {users.filter((e) => e.role === 'driver').map((e) => (
              <option value={e._id}>
                {e.name}
              </option>
            ))}
          </select>
          {/* {isFuture.toString()} */}
        </label>
        <br />
        <EnhancedTableToolbar tommorowClick={updateTommorowDriverToOrder} future={futureOrder} click={updateDriverToOrder} numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 700 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={orders.filter((e) => e.orderId !== '' && (e.status === 'Хүлээгдэж байна' || e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан')).length}
            />
            <TableBody>
              {orders
                .filter((e) => (e.status === 'Хүлээгдэж байна' || e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан'))
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row._id, row.order)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className={row.status === 'Хойшлуулсан' || row.status === 'Маргааш авах' ? 'isFuture' : ''}
                        component="th"
                        id={labelId}
                        scope="row"
                      // padding="none"
                      >
                        {row.phone} <br /> {row.address}
                      </TableCell>
                      <TableCell align="center">{
                        users.filter((e) => e._id === row.user).map((e) => (
                          <p>{e.name}</p>
                        ))
                      }</TableCell>
                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                      <TableCell align="center">{
                        users.filter((e) => e._id === row.driver).map((e) => (
                          <p>{e.name}</p>
                        ))
                      }</TableCell>
                      <TableCell align="center">{row.protein}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={orders
            .filter((e) => e.orderId !== '' && (e.status === 'Хүлээгдэж байна' || e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан'))
            .length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Зайг шахах"
      />
      {/* <br /> */}
    </Box>
  );
}