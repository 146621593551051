import React, { useEffect, useState } from 'react';
import styles from './Login.module.css';
import img from '../../Assets/Login.jpg';
import icon from '../../Assets/icon-2.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isSnackBar, setIsSnackBar] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [containers, setContainers] = useState([]);

  const userCache = JSON.parse(window.localStorage.getItem('userId'));
  const containerId = window.localStorage.getItem('containerId');

  useEffect(() => {
    if (userCache && userCache.name !== null) {
      navigate('/dashboard');
    }

    if (isLoading) {
      axios.get('https://api.turbodelivery.mn/areacontainer')
        .then((e) => {
          setContainers(e.data.data);
          setIsLoading(false)
        })
        .catch((e) => console.log(e))
    }

  }, [userCache, isLoading]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const login = () => {
    axios
      .post('https://api.turbodelivery.mn/user/login', {
        email: email,
        password: password,
      })
      .then((response) => {
        // alert(containers.find((e) => e.user === e.data.user.id)._id);
        const userData = response.data.user;
        const container = containers.find((container) => container.user === userData.id);
        // alert(e.data.user.id);
        // var container = ;
        if (userData.role === 'operator') {
          if (container) {
            const containerId = container._id;
            window.localStorage.setItem('userId', JSON.stringify(userData));
            window.localStorage.setItem('containerId', containerId);
            if (userData && containerId) {
              window.location.reload(false);
            } else {
              // Handle case where no container is found for the user
              console.error(containerId);
            }
          } else {
            // Handle case where no container is found for the user
            console.error('Container not found for the user');
          }
        } else {
          setRoleError(true);
        }
      })
      .catch(() => setIsSnackBar(true));
  };

  const handleClose = () => {
    setIsSnackBar(false);
    setRoleError(false);
  };

  return (
    <>{isLoading ? <div className="loading-container"><div className="loading"></div></div> :
      <div className={styles.container}>
        <div className={styles.img_item}>
          <img src={img} alt="" />
        </div>
        <div className={styles.login}>
          <img src={icon} alt="" />
          <div>
            <Snackbar
              className={styles.snackbar}
              open={isSnackBar}
              autoHideDuration={10000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                iconMapping={{ vertical: 'top', horizontal: 'right' }}
                className={styles.alert}
              >
                Имэйл эсвэл нууц үг буруу байна.
              </Alert>
            </Snackbar>
            <Snackbar
              className={styles.snackbar}
              open={roleError}
              autoHideDuration={10000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                iconMapping={{ vertical: 'top', horizontal: 'right' }}
                className={styles.alert}
              >
                Зөвхөн нярав эрхээр нэвтрэх боломжтой.
              </Alert>
            </Snackbar>
            <label style={{ alignItems: 'center' }}>
              <p>Нэвтрэх нэр</p>
              <input
                type="email"
                placeholder="Нэвтрэх нэр"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <label style={{ alignItems: 'center' }}>
              <p>Нууц үг</p>
              <input
                type="password"
                placeholder="Нууц үг"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </label>
          </div>
          <button onClick={login} style={{ width: window.screen.width > 768 ? '14vw' : '50vw' }} className="button">
            Нэвтрэх
          </button>
        </div>
      </div>
    }</>
  );
};

export default Login;
