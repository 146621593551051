import React from 'react'
import ContainerTable from '../../Table/ContainerTable'

const ContainerItemsDetialModal = ({ containers, user, users, filterStatus, shops, closeModal }) => {
    return (
        <div className='blur_container'>
            <div className="modal" style={{ width: '80vw' }}>
                <div className="two-line" style={{ width: '80vw' }}>
                    <h2>Захиалгын бараанууд</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal} >X</h2>
                </div>
                <ContainerTable containers={containers} user={user}
                    users={users} filterStatus={filterStatus} shops={shops} />
            </div>
        </div>
    )
}

export default ContainerItemsDetialModal
